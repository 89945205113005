$(document).delegate('body', 'click', function() {
    var $shoppingItem = $(".shopping-cart");
    if ($shoppingItem.hasClass("active")) {
        $shoppingItem.removeClass("active");
    }
}).delegate('#cart', 'click', function(e) {
    e.stopPropagation();
    $(".shopping-cart").toggleClass("active");
}).delegate('#addtocart', 'click', function(e) {
    e.stopPropagation();
    $(".shopping-cart").toggleClass("active");
});

$('.shopping-cart').each(function() {
    var delay = $(this).index() * 50 + 'ms';
    $(this).css({
        '-webkit-transition-delay': delay,
        '-moz-transition-delay': delay,
        '-o-transition-delay': delay,
        'transition-delay': delay
    });
});