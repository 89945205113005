$(document).delegate('#js-desktop-search-btn', 'click', function() {
    $('#header-search-section').toggle();
    $('#js-search-input').focus();
}).delegate('.js-search-btn', 'click', function() {
    $('.mobile-header-search').slideToggle('slow');
    $('#js-search-mobile-input').focus();
});

// Mobile header ajax search
$(document).ready(function() {
    var headerAjaxTimeout = null;
    $('#js-header-search-mobile input').unbind().keyup(function(e) {
        var code = e.keyCode || e.which;
        if (code !== 13) {
            var value = $(this).val();
            if (value.length > 2 ) {
                clearTimeout(headerAjaxTimeout);
                headerAjaxTimeout = setTimeout(function() {
                    searchDataMobile(value);
                }, 200);

            } else {
                $('#js-header-search-mobile-result').hide();
            }
        }

        if (code === 27) {
            $('#js-header-search-mobile-result').hide();
        }
    });
});
$(document).click(function(event) {
    if (! $(event.target).closest('#js-header-search-mobile-result').length) {
        $('#js-header-search-mobile-result').hide();
    }
});
function searchDataMobile(val){
    $('#js-header-search-mobile-result').show();
    $('#js-header-search-mobile-result').html('<div class="header-search-loading">' + headerSearchLoadingTxt + '</div>');
    $.post(headerSearchUrl, {'q': val}, function(data){
            if (data !== "") {
                $('#js-header-search-mobile-result').html(data);
            } else {
                $('#js-header-search-mobile-result').html('<div class="header-search-info">' + headerSearchInfoTxt + '</div>');
            }

        }
    ).fail(function(xhr, ajaxOptions, thrownError) {
        console.log(thrownError);
    });
}

// Desktop header ajax search
$(document).ready(function() {
    var headerAjaxTimeout = null;
    $('#js-header-search input').unbind().keyup(function(e) {
        var code = e.keyCode || e.which;
        if (code !== 13) {
            var value = $(this).val();
            if (value.length > 2 ) {
                clearTimeout(headerAjaxTimeout);
                headerAjaxTimeout = setTimeout(function() {
                    searchData(value);
                }, 200);

            } else {
                $('#js-header-search-result').hide();
            }
        }

        if (code === 27) {
            $('#js-header-search-result').hide();
        }
    });
});
$(document).click(function(event) {
    if (! $(event.target).closest('#js-header-search-result').length) {
        $('#js-header-search-result').hide();
    }
});
function searchData(val){
    $('#js-header-search-result').show();
    $('#js-header-search-result').html('<div class="header-search-loading">' + headerSearchLoadingTxt + '</div>');
    $.post(headerSearchUrl, {'q': val}, function(data){
            if (data !== "") {
                $('#js-header-search-result').html(data);
            } else {
                $('#js-header-search-result').html('<div class="header-search-info">' + headerSearchInfoTxt + '</div>');
            }

        }
    ).fail(function(xhr, ajaxOptions, thrownError) {
        console.log(thrownError);
    });
}

// Mobile Menu
$('.drawermenu').drawermenu({
    speed:100,
    position:'left'
});

// Fixed Navigation
var $navbar = $(".navigation-wrap"),
    y_pos = $navbar.offset().top,
    height = $navbar.height();

$(document).scroll(function() {
    var scrollTop = $(this).scrollTop();

    if (scrollTop > y_pos + height) {
        $navbar.addClass("navbar-fixed").animate({
            top: 0
        });
    } else if (scrollTop <= y_pos) {
        $navbar.removeClass("navbar-fixed").clearQueue().animate({
            top: "-18px"
        }, 0);
    }
});