// Slider Products
var sliderCircled = $('.slick-slider');
sliderCircled.owlCarousel({
    margin:15,
    dots:false,
    nav:true,
    responsive:{
        0:{
            items:4,
            nav:true,
            navText: false
        },
        600:{
            items:6,
        },
        1000:{
            items:10,
            nav:true,
            navText: false
        }
    },
    lazyLoad: true
});

// Slider Home
var sliderHome = $('.slider-home');
sliderHome.owlCarousel({
    loop:true,
    margin:10,
    nav:false,
    dots:false,
    animateOut: 'fadeOut',
    autoplay:true,
    autoplaySpeed:2500,
    autoplayHoverPause:true,
    navSpeed:1000,
    lazyLoad: true,
    responsive:{
        0:{
            items:1
        },
        600:{
            items:1
        },
        1000:{
            items:1
        }
    }
});
$('.home-next-btn').click(function() {
    sliderHome.trigger('next.owl.carousel');
    sliderHome.trigger('stop.owl.autoplay');
});
$('.home-prev-btn').click(function() {
    sliderHome.trigger('prev.owl.carousel', [300]);
    sliderHome.trigger('stop.owl.autoplay');
});

// Slider Products
var sliderProducts = $('.owl-products');
sliderProducts.owlCarousel({
    margin:15,
    dots:false,
    nav:true,
    responsive:{
        0:{
            items:2,
            nav:true,
            navText: false
        },
        600:{
            items:2,
        },
        1000:{
            items:4,
            nav:true,
            navText: false
        }
    },
    lazyLoad: true
});