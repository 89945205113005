
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

window.$ = window.jQuery = require('jquery');
require('bootstrap');
require('../../../../../node_modules/@fancyapps/fancybox/dist/jquery.fancybox');
require('./modules/jquery.crypt');
require('./modules/lazysizes.min');
require('./modules/essence-drawermenu');
window.Swal = require('sweetalert2');
require('owl.carousel');
require('jquery-validation');
require('../../../../../node_modules/jquery-validation/dist/additional-methods');
require('rateyo');
require('jquery-blockui');
require('../../../../../node_modules/select2/dist/js/select2.full');

/**
 * Theme
 */
require('./themes/mobile-slider');
require('./themes/cart_dropdown');
require('./themes/header');
require('./themes/footer');
require('./themes/components/sliders');
require('./themes/components/alerts');
require('./themes/components/sticky-nav');
require('./themes/components/validator');
require('./themes/helpers/ajax-clicks');
$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

$.blockUI.defaults.message = '<i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>';

// GTM Product Click
$('[data-gtm-click]').on('click', function(e) {
    e.preventDefault();

    $productUrl = $(this).attr('href');
    $actionList = $(this).data('gtm-list');

    dataLayer.push({
        'event': 'productClick',
        'ecommerce': {
            'click': {
                'actionField': {'list': $actionList},
                'products': [$(this).data('gtm-product')]
            }
        },
        'eventCallback': function() {
            document.location = $productUrl;
        }
    });
});

// Product Video
$(".product-video-button-start").click(function() {
    var video = document.getElementById("product-video");
    $('.video-cover').addClass('video-started');
    $('.product-video-button-start').css('display','none');

    if (video.paused) {
        video.play();
    } else {
        video.pause();
    }

    video.onended = function() {
        $('.video-cover').removeClass('video-started');
        $('.product-video-button-start').css('display','block');
    };
});

// Rating
$(".js-rating").rateYo({
    normalFill: ratingNormalFill,
    ratedFill: ratingRatedFill,
    starWidth: "18px",
    fullStar: true,
    onSet: function (rating, rateYoInstance) {
        $('#rating').val(rating);
    }
});
$(".js-rating-ready-sm").rateYo({
    normalFill: ratingNormalFill,
    ratedFill: ratingRatedFill,
    starWidth: "12px",
    fullStar: true,
    readOnly: true
});
$(".js-rating-ready").rateYo({
    normalFill: ratingNormalFill,
    ratedFill: ratingRatedFill,
    starWidth: "18px",
    fullStar: true,
    readOnly: true
});

$('.js-category-filter-btn').on('click', function () {
    $('.section-category-filter .filter-groups').slideToggle('slow');
});

$(".js-select2").select2({
    dropdownParent: $('.country-select')
});

// Ayni sayfada birden fazla üst üste modal acmak icin
$(document).on('show.bs.modal', '.modal', function (event) {
    var zIndex = 800 + (10 * $('.modal:visible').length);
    $(this).css('z-index', zIndex);
    setTimeout(function() {
        $('.modal-backdrop').not('.modal-stack').css('z-index', zIndex - 1).addClass('modal-stack show');
    }, 0);
});

// Nav Category List Show/Hide
jQuery('.show-categories').on('click', function() {
    jQuery('.product-categories').slideToggle('slow');
});

// Product Quantity increment and decrement
window.incrementQuantity = function (incrementor, target) {
    var value = parseInt(document.getElementById(target).value);
    value += incrementor;
    if (value === 0) {
        value = 1;
    }

    document.getElementById(target).value = value;
};