// Alerts
window.errorAlert = function (message) {
    Swal.fire({
        title: alertErrorTitleTxt,
        text: message,
        icon: 'error',
        confirmButtonText: alertCloseTxt
    });
};
window.warningAlert = function (message) {
    Swal.fire({
        title: alertWarningTitleTxt,
        text: message,
        icon: 'warning',
        confirmButtonText: alertCloseTxt
    });
};
window.successAlert = function (message) {
    Swal.fire({
        title: alertSuccessTitleTxt,
        text: message,
        icon: 'success',
        confirmButtonText: alertCloseTxt
    });
};