var modal_counter = 0;

$(document).delegate('.ajax-form', 'submit', function(event) {
    var ajaxStoreForm = $(this);

    if (ajaxStoreForm.attr('method') === 'GET') {
        return true;
    }

    event.preventDefault();

    var push_div_id = ajaxStoreForm.data('div-id');

    $.ajax({
        type: ajaxStoreForm.attr('method'),
        url: ajaxStoreForm.attr('action'),
        data: ajaxStoreForm.serialize(),
        beforeSend:function() {

        },
        success: function(response) {
            if (typeof push_div_id === "undefined") {
                if (typeof response.redirect_url === "undefined") {
                    if (! response.status) {
                        errorAlert(response.message);
                    } else {
                        successAlert(response.message);
                    }
                } else {
                    document.location.href = response.redirect_url;
                }
            } else {
                if (typeof response.return_html !== 'undefined') {
                    $('#' + push_div_id).html(response.return_html);
                    ajaxStoreForm.parents('.modal').modal('hide');
                    successAlert(response.message);
                } else {
                    if (response.status === 'error') {
                        errorAlert(response.message);
                    } else {
                        successAlert(response.message);
                    }
                }
            }
        },
        error: function(jqXhr) {
            if ( jqXhr.status === 422 ) {
                $errors = jqXhr.responseJSON['errors'];

                if (! ajaxStoreForm.find('.form-errors').length) {
                    ajaxStoreForm.prepend('<div class="form-errors"></div>');
                }

                errorsHtml = '<div class="alert alert-danger"><ul>';
                $.each($errors, function(key, value) {
                    errorsHtml += '<li>' + value[0] + '</li>';
                });
                errorsHtml += '</ul></di>';
                ajaxStoreForm.find('.form-errors').html(errorsHtml);
            } else if (jqXhr.status === 403) {
                errorAlert(jqXhr.responseJSON.error);
            } else {
                errorAlert(jqXhr.responseJSON.message);
            }
        },
        complete: function () {

        }
    });
}).delegate('.ajax-post', 'click', function() {
    /**
     * Use: <a data-message="uyarı mesajınız" data-url="post edilecek url"
     * data-list="json olarak post edilecek datalar" data-modal="true"
     * class="ajax-post">open</a>
     */
    $select_wrapper = $(this);
    var box_message = $select_wrapper.data('message');
    var post_url = $select_wrapper.data('url');
    var post_data = $select_wrapper.data('list');
    var method_data = $select_wrapper.data('method');
    $is_modal = $select_wrapper.data('modal');
    var modalId = $().crypt({
        method: "md5",
        source: post_url
    });

    if (typeof method_data === 'undefined') {
        method_data = 'POST';
    }

    if (typeof box_message !== 'undefined') {

        bootbox.dialog({
            message: box_message,
            title: alertBoxTitleTxt,
            buttons: {
                danger: {
                    label: alertBoxOkTxt,
                    className: "btn-success",
                    callback: function() {
                        $.ajax({
                            type: method_data,
                            url: post_url,
                            data: post_data,
                            beforeSend:function() {
                                $.blockUI();
                            },
                            success: function(response){
                                if (typeof response.redirect_url !== 'undefined') {
                                    document.location.href = response.redirect_url;
                                }
                            },
                            error: function(jqXhr){
                                if (jqXhr.status === 403) {
                                    errorAlert(jqXhr.responseJSON.error);
                                }
                            },
                            complete: function () {
                                $.unblockUI();
                            }
                        });
                    }
                },
                main: {
                    label: alertBoxCancelTxt,
                    className: "btn-primary",
                    callback: function() {}
                }
            }
        });

    } else {

        $.ajax({
            type: method_data,
            url: post_url,
            data: post_data,
            beforeSend:function() {

            },
            success: function(response){
                if ($is_modal === true) {
                    console.log(modalId);
                    if ($('#' + modalId).length === 0) {
                        $('#js-modals-here').append(response);
                    }
                    $('#' + modalId).modal('show');
                } else {
                    if (typeof response.redirect_url !== 'undefined') {
                        document.location.href = response.redirect_url;
                    } else {
                        successAlert(response.message);
                        if (typeof response.return_html !== 'undefined') {
                            $select_wrapper.html(response.return_html);
                        }
                    }
                }
            },
            error: function(jqXhr){
                if (jqXhr.status === 403) {
                    errorAlert(jqXhr.responseJSON.error);
                } else if (jqXhr.status === 401) {
                    errorAlert(jqXhr.responseJSON.data.message);
                }
            },
            complete: function () {

            }
        });

    }

    return false;
}).delegate('.ajax-delete', 'click', function() {
    /**
     * Use: <a data-message="uyarı mesajınız" data-url="post edilecek url" data-delete-id="div-12"
     * class="ajax-delete">delete</a>
     */
    var box_message = $(this).data('message');
    if (typeof box_message === 'undefined') {
        box_message = alertConfirmTxt;
    }
    var post_url = $(this).data('url');
    var delete_div_id = $(this).data('delete-id');

    Swal.fire({
        title: alertConfirmTitleTxt,
        text: box_message,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: alertConfirmCancelTxt,
        confirmButtonText: alertConfirmOkTxt
    }).then((result) => {
        console.log(result);
        if (result.value) {
            $.ajax({
                type: 'POST',
                url: post_url,
                data: {_method: 'DELETE'},
                beforeSend:function() {
                    $.blockUI();
                },
                success: function(response){
                    if (response.status === 'success') {
                        if (typeof response.redirect_url !== 'undefined') {
                            document.location.href = response.redirect_url;
                        } else {
                            $('#' + delete_div_id).remove();
                            successAlert(response.message);
                        }
                    } else {
                        errorAlert(response.message)
                    }
                },
                error: function(jqXhr){
                    if (jqXhr.status === 403) {
                        errorAlert(jqXhr.responseJSON.message);
                    } else if (jqXhr.status === 401) {
                        errorAlert(jqXhr.responseJSON.message);
                    } else {
                        console.log(jqXhr);
                    }
                },
                complete: function () {
                    $.unblockUI();
                }
            });
        }
    });

    return false;
}).delegate('.ajax-modal', 'click', function(){
    /**
     * Use: <a data-url="open_url" class="ajax-modal">open</a>
     */
    var ajaxUrl = $(this).attr('data-url');
    var modalId = $().crypt({
        method: "md5",
        source: ajaxUrl
    });

    $.ajax({
        type: 'GET',
        url: ajaxUrl,
        beforeSend:function() {

        },
        success: function(response){
            if ($('#' + modalId).length === 0) {
                $('#js-modals-here').append(response);
            }
            $('#' + modalId).modal('show');
        },
        error: function(jqXhr) {
            if ( jqXhr.status === 402 ) {
                if ($('#' + modalId).length === 0) {
                    $('#js-modals-here').append(jqXhr.responseText);
                }
                $('#' + modalId).modal('show');
            } else if ( jqXhr.status === 403 ) {
                errorAlert(jqXhr.responseJSON.error);
            } else if ( jqXhr.status === 401 ) {
                errorAlert(jqXhr.responseJSON.data.message);
            } else {
                errorAlert(jqXhr.responseJSON.message);
            }
        },
        complete: function () {

        }
    });
}).delegate('[data-select-ajax="true"]', 'change', function(){
    /**
     * Use: <select data-url="post_url" data-select-ajax="true" data-method="POST"><option>...</option></select>
     * Result: json {status: false|true, data: {push_id: "push edilecek select id", values: [id: "title"]}}
     */
    var dump_data = '';
    var data_url = $(this).data('url');
    var data_select_val = $(this).val();
    var ajax_method = $(this).data('method');

    if (typeof ajax_method === 'undefined') {
        ajax_method = 'POST';
    }

    $.ajax({
        type: ajax_method,
        url: data_url,
        data: { select_val: data_select_val },
        beforeSend:function() {

        },
        success: function(response){
            $.each(response.data.values, function(index, value) {
                dump_data += '<option value="'+index+'">'+value+'</option>';
            });

            $('#' + response.data.push_id).html(dump_data);
        },
        error: function(jqXhr){
            console.log(jqXhr);
        },
        complete: function () {

        }
    });
}).delegate('.modal', 'shown.bs.modal', function() {
    modal_counter++;
}).delegate('.modal', 'hidden.bs.modal', function() {
    modal_counter--;
    if (modal_counter) {
        $('body').addClass('modal-open');
    } else {
        console.log($(this).closest('#js-modals-here').length);
        if ($(this).closest('#js-modals-here').length > 0) {
            $(this).remove();
        }
        $('body').removeClass('modal-open');
    }
});