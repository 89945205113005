var componentSliderContainer = $(".mobile-slider-container");
var componentSlider;
var componentSliderIsInitalized = false;
var slideCount = 0;

$(function () {
    $(window).on("load", function () {
        // refreshSlider()
    });
    if ($(window).width() <= 979) {
        initComponentSlider();
    }

    $(window).resize(function () {
        if (componentSliderIsInitalized == true && $(window).width() >= 979) {
            destroyComponentSlider();
        }
        else if (!componentSliderIsInitalized && $(window).width() <= 979) {
            initComponentSlider();
        }
    });
});

function initComponentSlider() {
    componentSliderContainer.addClass("owl-carousel owl-theme");
    componentSlider = componentSliderContainer.owlCarousel({
        margin: 0,
        nav: false,
        dots: true,
        loop: true,
        rtl: false,
        autoHeight: true,
        items: 1,
        onInitialized: function (e) {
            componentSliderIsInitalized = true;
            slideCount = e.item.count;
            setTimeout(function () {
                refreshSlider();
            }, 500)
        },
        onTranslated: function () {

        },
    });
}
function destroyComponentSlider() {
    if (componentSliderIsInitalized) {
        componentSlider.owlCarousel("destroy");
        componentSliderIsInitalized = false;
        componentSliderContainer.removeClass("owl-carousel owl-theme");
    }
}
function refreshSlider() {
    componentSlider.trigger('refresh.owl.carousel');
}